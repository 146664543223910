.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
 
}


.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 2vmin);
  color: #064A6F;
  font-weight: 600;
  background: radial-gradient(ellipse at center, #ffffff 55%, #064A6F 180%);

}
@media (max-width: 768px) {

  .App-header{
    font-size: calc(13px + 2vmin);
  }
}